.rootContainer {
    display: flex;
    width: 100%;
}



.container {
    padding: 28px 64px;
    /* background-color: rgba(255, 234, 219, 0.3); */
    width: 100%;
    display: flex;
    flex-direction: column;
  



}

.header {
    width: 100%;
    display: grid;
    place-items: center;

}


.body {

    flex: 1;
    /* height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
ba
    justify-content: space-evenly; */
}

.indicatorContainer {
    /* margin-top: 20px; */
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 31px;

}


.cardTitle {
    font-size: 23px;
    font-weight: 600;
    text-align: center;
    font-family: 'Raleway';
    margin-top: 20px;
    animation: 1s ease-in-out 0s fadeIn;
}

.cardText {
    height: 100px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;


}

.cardContainer {
    display: flex;
    flex: 1;
    height: 100%;

    flex-direction: column;
    justify-content: space-evenly;
    animation: fadeIn 5s;

}

.textContainer {
    animation: 5s ease 0s fadeIn;
}

.textContainer h1 {
    display: block !important;
}

.svgContainer {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.svgContainer svg {
    animation: 7s ease fadeIn;
    height: 150px;

}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.containerCnpjInput {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

@media only screen and (max-width: 740px) {
    .rootContainer {
        flex-direction: column-reverse;
        width: 100%;

    }

    .container {

        /* background-color: rgba(255, 234, 219, 0.3); */
        /* background-color: 'red'; */
        width: 100%;
        height: 125vh;
        display: flex;
        flex-direction: column;

    }
    .containerCnpjInput {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
    }
}